/* Add this to your global stylesheet or component-specific styles */
@keyframes borderAnimation {
    from {
        border-color: transparent;
    }

    to {
        border-color: white;
    }
}

.animated-border {
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    transition: border-color 1s ease-in-out;
}

.hover\:animated-border:hover {
    animation: borderAnimation 1s forwards;
}

.blur-effect {
    transition: filter 0.3s ease-in-out;
}

.blur-effect:hover {
    filter: blur(4px);
}

.feature-container:hover .feature-item {
    filter: blur(4px);
}

.feature-container:hover .feature-item:hover {
    filter: none;
}